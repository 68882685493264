import { VALIDATION_PATTERNS } from 'dyl-components';

const googlePhoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

export default class PhoneUtil {
    static formatPhoneNumber(phoneNumber, countryCode = 'US') {
        try {
            const phoneNumberString = `${phoneNumber}`;
            const formattedPhoneNumber = googlePhoneUtil.parseAndKeepRawInput(phoneNumberString, countryCode);
            return googlePhoneUtil.format(formattedPhoneNumber, PNF.NATIONAL);
        } catch (e) {
            return phoneNumber || 'N/A';
        }
    }

    static formatPhoneNumbersIn(itemsContainingPhoneNumbers) {
        let formattedCallRecords = [];
        itemsContainingPhoneNumbers.forEach((item) => {
            let itemCopy = Object.assign({}, item);
            let phoneNumber = itemCopy.phone;
            itemCopy.phone = PhoneUtil.formatPhoneNumber(phoneNumber);
            formattedCallRecords.push(itemCopy);
        });
        return formattedCallRecords;
    }

    static asYouTypeFormatter(number) {
        if (number) {
            let prefix = "";
            let numberAux = number;

            if (/^\*\d.*$/.test(number)) {
                prefix = `${number.substring(0, 2)} `;
                numberAux = number.substring(2) || "";
            }

            const stringLength = numberAux.length;
            if (numberAux) {
                if (stringLength > 9) { numberAux = this.formatPhoneNumber(numberAux) }
                else if (stringLength > 6) { numberAux = `${numberAux.slice(0, 3)}-${numberAux.slice(3, 6)}-${numberAux.slice(6)}`; }
                else if (stringLength > 4) { numberAux = `${numberAux.slice(0, 3)}-${numberAux.slice(3)}`; }
            }

            return `${prefix}${numberAux}`.trim();
        }
        return "";
    }

    static getUnformatted(phoneNumber) {
        return `${phoneNumber}`.replace(/\D/g, '');
    }

    static isValid(phoneNumber, countryCode = 'US') {
        let phoneTest = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
        return phoneNumber && phoneTest.test(phoneNumber);
    }

    static formatPhoneNumberForPayload(phoneNumber) {
        return `+1${phoneNumber}`;
    }

    static isValidAreaCode(value) {
        return `${value}`.match(/^[0-9]{0,3}$/);
    }

    static sanitizePhoneNumber(value){
        //Remove extra characters & remove appended 1
        const removeNonDigits = `${value}`.replace(/\D/g, "").replace(/^1/g, "");
        //Test for ONLY digits, if not pass original value.
        return VALIDATION_PATTERNS.number.test(removeNonDigits) ? removeNonDigits : value;
    }

    static onAddPhone (phone, phonesToEdit, addPhone) {
        const mainPhone = phonesToEdit.find((phoneToEdit) => phoneToEdit.main)
        phone.main = !mainPhone;
        addPhone(phone)
    }

    static insertExtension (extension, extensions) {
        const index = extensions.findIndex((ext) => ext.value > extension);

        if (index > -1) {
            const firstPart = extensions.slice(0, index);
            const secondPart = extensions.slice(index);
            return [...firstPart, {key: extension, value: extension, text: extension}, ...secondPart];
        }

        return [...extensions, {key: extension, value: extension, text: extension}];
    }

    static DESTINATION_TYPES_OPTIONS = [
        { key: "call_queue", value: "call_queue", text: "Call Queue", options: [] },
        { key: "conference_room", value: "conference_room", text:  "Conference Room", options: [] },
        { key: "destination_group", value: "destination_group", text: "Destination Groups", options: [] },
        { key: "direct_extension", value: "direct_extension", text: "Direct Extension", options: [] },
        { key: "forwarding_number", value: "forwarding_number", text: "Forwarding Number", options: [] },
        { key: "ivr", value: "ivr", text: "IVR Prompt", options: [] },
        { key: "ring_group", value: "ring_group", text: "Ring Group", options: [] },
        { key: "routing_rule", value: "routing_rule", text: "Call Routing Rules", options: [] },
        { key: "parking_lot", value: "parking_lot", text: "Parking Lot", options: [] },
        { key: "personal_voicemail", value: "personal_voicemail", text: "Personal VM Box", options: [] },
        { key: "company_voicemail", value: "company_voicemail", text: "Company VM Box", options: [] },
    ]

    static EXIT_KEY_OPTIONS = [
        { key: "#", value: "#", text: "#" },
        { key: "*", value: "*", text: "*" },
        { key: "0", value: "0", text: "0" },
        { key: "1", value: "1", text: "1" },
        { key: "2", value: "2", text: "2" },
        { key: "3", value: "3", text: "3" },
        { key: "4", value: "4", text: "4" },
        { key: "5", value: "5", text: "5" },
        { key: "6", value: "6", text: "6" },
        { key: "7", value: "7", text: "7" },
        { key: "8", value: "8", text: "8" },
        { key: "9", value: "9", text: "9" },
    ];

    static DESTINATION_TYPES = [
        { key: "call_queue", value: "call_queue", text: "Call Queue" },
        { key: "destination_group", value: "destination_group", text: "Destination Groups" },
        { key: "direct_extension", value: "direct_extension", text: "Direct Extension" },
        { key: "forwarding_number", value: "forwarding_number", text: "Forwarding Number" },
        { key: "ivr", value: "ivr", text: "IVR Prompt" },
        { key: "ring_group", value: "ring_group", text: "Ring Group" },
        { key: "routing_rule", value: "routing_rule", text: "Call Routing Rules" },
        { key: "personal_voicemail", value: "personal_voicemail", text: "Personal VM Box" },
        { key: "company_voicemail", value: "company_voicemail", text: "Company VM Box" },
    ]

    static SOUND_TYPES = [
        { key: "general", value: "general", text: "General" },
        { key: "personal_voicemail", value: "personal_voicemail", text: "Personal VM Box" },
        { key: "company_voicemail", value: "company_voicemail", text: "Company VM Box" },
        { key: "ivr", value: "ivr", text: "IVR Prompt" },
        { key: "vmdrop", value: "vmdrop", text: "Voicemail Drop" },
        { key: "music", value: "music", text: "Music on Hold" },
    ]

    static END_OF_LINE_TYPES = ["personal_voicemail", "company_voicemail", "ivr"];

    static DESTINATION_GROUPS = ["conference_room", "destination_group", "routing_rule", "parking_lot", "personal_voicemail"];

    static RING_DURATION_OPTIONS = [
        {key: 15, value: 15, text: "15 seconds"},
        {key: 30, value: 30, text: "30 seconds"},
        {key: 60, value: 60, text: "60 seconds"},
        {key: 90, value: 90, text: "90 seconds"},
        {key: 120, value: 120, text: "2 minutes"},
        {key: 300, value: 300, text: "5 minutes"},
        {key: 900, value: 900, text: "15 minutes"},
        {key: 1800, value: 1800, text: "30 minutes"},
        {key: 3600, value: 3600, text: "1 hour"},
        {key: 7200, value: 7200, text: "2 hours"},
    ]

    static DESTINATION_TYPES_ICONS = {
        direct_extension: "fa-solid fa-circle-user",
        forwarding_number: "fa-solid fa-arrow-right-from-line",
        ring_group: "fa-solid fa-user-clock",
        call_queue: "fa-solid fa-phone-volume",
        ivr: "fa-solid fa-file-audio",
        routing_rule: "fa-solid fa-route",
        personal_voicemail: "fa-solid fa-voicemail",
        company_voicemail: "fa-solid fa-voicemail",
        conference_room: "fa-solid fa-people",
        parking_lot: "fa-solid fa-car"
    }

    static DESTINATION_TYPES_AUDIO_ICONS = {
        ...this.DESTINATION_TYPES_ICONS,
        conference_room: "fa-solid fa-people",
        parking_lot: "fa-solid fa-car",
        phone_number: "fa-solid fa-phone",
    }

    static DESTINATION_TYPES_URLS = {
        forwarding_number: "/settings/phone-management/numbers/dyl-numbers",
        ring_group: "/settings/phone-management/routing/ring-group-call-queues",
        call_queue: "/settings/phone-management/routing/ring-group-call-queues",
        ivr: "/settings/phone-management/routing/ivr-prompts",
        routing_rule: "/settings/phone-management/routing/call-routing-rules",
        company_voicemail: "/settings/phone-management/extensions/company",
        personal_voicemail: "/settings/phone-management/extensions/users",
        destination_group: "/settings/phone-management/routing/destination-groups",
        phone_number: "/settings/phone-management/numbers/dyl-numbers"
    }

    static COMPANY_EXTENSION_TYPES = [
        { key: "company_voicemail", value: "company_voicemail", text: "Company VM Box" },
        { key: "conference_room", value: "conference_room", text:  "Conference Room" },
        { key: "parking_lot", value: "parking_lot", text: "Parking Lot" },
    ]

    static DEVICE_TYPES_OPTIONS = [
        { key: "browser", value: "browser", text: "DYL Browser Softphone" },
        { key: "hardphone", value: "hardphone", text:  "Hardphone" },
        { key: "softphone", value: "softphone", text: "Outside Softphone" },
    ]
}
