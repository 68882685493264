import { Table } from "dyl-components";
import { Icon } from "semantic-ui-react";

const Subscription = ({
    price,
    productInfo,
    user,
    RowCheckboxComponent,
    id,
    canceled,
    onRestore,
    restored
}) => {
    return (
        <Table.Row>
            {RowCheckboxComponent && (
                <Table.Cell>
                    <RowCheckboxComponent
                        id={id}
                        disabled={user?.active || (canceled && !restored)}
                        onRemove={restored ? () => {onRestore(id)} : null}
                    />
                </Table.Cell>
            )}
            <Table.Cell>{productInfo}</Table.Cell>
            <Table.Cell>
                {user && (
                    <div>
                        <div>
                            <Icon className="fas fa-circle-user" /> {user.name}
                        </div>
                        <div>Ext. {user.extension}</div>
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>
                ${price} {
                    RowCheckboxComponent && canceled && !restored && (
                        <Icon
                            className="fa-sharp fa-solid fa-arrow-rotate-left Pipeline__stage-undo-icon"
                            size="large"
                            link
                            onClick={onRestore}
                        />
                    )
                }
            </Table.Cell>
        </Table.Row>
    );
};

export default Subscription;
