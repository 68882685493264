import React, { useState, useContext } from 'react';
import { EllipsisDropdown, Person, Table } from 'dyl-components';
import { useDispatch } from 'react-redux';
import { Checkbox, DropdownItem } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import { StringUtils } from 'utils';
import campaignActions from 'actions/campaigns';


export const UsersRow = ({ user, campaignId, readUsers }) => {
    const [selectedUsers, setSelectedUsers] = useContext(BulkActionsContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dispatch = useDispatch();

    const isSelected = (user_id) => {
        return selectedUsers.includes(user_id);
    }

    const onToggleUser = (user_id) => {
        const newSelectedUsers = isSelected(user_id) ? selectedUsers.filter(id => user_id !== id) : [...selectedUsers, user_id];
        setSelectedUsers(newSelectedUsers);
    }

    const countsToDisplay = Object.keys(user.campaign_converted_count).map(key => ({
        type: key,
        count: user.campaign_converted_count[key]
    })).filter(stat => stat.count);

    countsToDisplay.sort((a, b) => a.key > b.key);

    const removeAndUnRemoveCampaign = async (user_id, isDeleted) => {
        if(isDeleted){
            setIsDropdownOpen(false);
            await dispatch(campaignActions.createCampaignUserReport([user_id],null,campaignId));
            readUsers();
            
        }else{
            setIsDropdownOpen(false);
            await dispatch(campaignActions.deleteCampaignUser(campaignId,{user_id}));
            readUsers();
        }
        
    }


    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(user.user_id)} onChange={() => { onToggleUser(user.user_id) }} />
            </Table.Cell>
            <Table.Cell>
                <Person
                    username={user.user.username}
                    email={user.user.email}
                />
            </Table.Cell>
            <Table.Cell>
                {user.conversion_rate}%
            </Table.Cell>

            <Table.Cell>
                {countsToDisplay.length ? countsToDisplay.map(stat => (
                    <div key={stat.type}>{stat.count} {stat.count === 1 ? stat.type : StringUtils.capitalize(stat.type === "opportunity" ? "opportunities" : `${stat.type}s`)}</div>
                )) : 0}
            </Table.Cell>
            <Table.Cell>
                {user.total_calls}
            </Table.Cell>
            <Table.Cell>
                {user.attributed_revenue}
            </Table.Cell>
            <Table.Cell>
                {!user.is_owner && (
                    <EllipsisDropdown isDropdownOpen={isDropdownOpen} toggleEllipsisDropdown={()=>{setIsDropdownOpen(isDropdownOpen=>!isDropdownOpen)}}>
                    <DropdownItem
                        text={user?.deleted ? 'Unremove From Campaign' : 'Remove From Campaign'}
                        icon={user?.deleted ? "fa-sharp fa-solid fa-user-plus" : "fa-solid fa-user-slash"}
                        onClick={() => removeAndUnRemoveCampaign(user?.user_id, user?.deleted)}
                    />
                    </EllipsisDropdown>
                )}
            </Table.Cell>
        </Table.Row>
    );
}
