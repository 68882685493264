import React, { useCallback, useEffect, useState } from 'react';

import './index.scss';
import { Header, Icon } from 'semantic-ui-react';
import { OfficeViewContainer, OfficeViewHeader, OfficeViewLeftPanel, OfficeViewDialPad, generateResolver, yup, OfficeViewContent, OfficeViewPhoneMiddle, OfficeViewCompanyDestinations, Notification, STATUS_TYPES } from 'dyl-components';
import { useForm } from 'react-hook-form';
import contactsActions from 'actions/contacts';
import { useDispatch, useSelector } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';

const LIMIT = 15;
const PINNED_COMPANY_DESTINATIONS_LIMIT = 3;

const OfficeView = ({ isActive, onClose }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [search, setSearch] = useState("");
    const [searchMade, setSearchMade] = useState("");
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [isDialDisabled, setIsDialDisabled] = useState(false);
    const [isCompanyExtensionExpanded, setIsCompanyExtensionExpanded] = useState(false);
    const [displayedCompanyDestinations, setDisplayedCompanyDestinations] = useState(false);
    const [isPinnedDestinationsMaxed, setIsPinnedDestinationsMaxed] = useState(false);

    const { isSearchingContacts, contacts_searched_count, contact_lookup } = useSelector((state) => state.contacts);
    const { viewDestinations, isReadingViewDestinations } = useSelector((state) => state.pbx_config);

    const pages = [
            { id: 0, icon: "fa-solid fa-phone", component: OfficeViewPhoneMiddle },
            { id: 1, icon: "fa-solid fa-user-clock", component: () => <></> },
            { id: 2, icon: "fa-solid fa-comments", component: () => <></> },
            { id: 3, icon: "fa-solid fa-gear", component: () => <></> }
    ];

    const { formState: { isValid }, control, watch, setValue, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            dial: "",
        },
        resolver: generateResolver({
            dial: yup.string().minlength(3).required("")
        })
    });

    const dispatch = useDispatch();

    const dialedNumber = watch("dial");

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
        if (!value) {
            setIsSearchResultsVisible(false);
        }
    }

    const searchFunction = async () => {
        try {
            setPage(1);
            setSearchMade(search);
            setIsSearchResultsVisible(true);
            await dispatch(contactsActions.contactSearch({ page: 1, search, limit: LIMIT }));
        } catch (e) {
            console.log(e)
        }
    }

    const onPersonSelect = async (id) => {
        try {
            await dispatch(contactsActions.viewLookup(id));
            setPage(1);
            setSearch('');
            setIsSearchResultsVisible(false);
            setSearchMade("");
        } catch (error) {
            console.log(error)
        }
    }

    const handleNumberClick = (number) => {
        setValue('dial', number);
        trigger('dial');
    }

    const cancelFunction = async () => {
        setSearch("");
        setPage(1);
        setIsSearchResultsVisible(false);
        setSearchMade("");
    }

    const onPageChange = async (_, { activePage }) => {
        await dispatch(contactsActions.contactSearch({ page: activePage, search, limit: LIMIT }));
        setIsSearchResultsVisible(true);
        setPage(activePage);
    }

    const onRemovePerson = () => {
        dispatch(contactsActions.removeViewLookup());
    }

    const handleClose = useCallback(() => {
        cancelFunction();
        onClose();
    }, [onClose])

    const toggleIsExpanded = async () => {
        setIsCompanyExtensionExpanded((prevValue) => !prevValue)
        await dispatch(pbxConfigActions.viewDestinations());
    }

    const togglePinned = async (destination_id, pinned) => {
        try {
            await dispatch(pbxConfigActions.setPinned({ destination_id, pinned }));
            await dispatch(pbxConfigActions.viewDestinations());
            Notification.alert('Company destination pinned successfully', STATUS_TYPES.SUCCESS);
        } catch (error) {
            Notification.alert('Failed to pin company destination', STATUS_TYPES.ERROR);
            console.log(error)
        }
    }
    
    useEffect(() => {
        if (contact_lookup) {
            const number = contact_lookup.contact_id ? (
                contact_lookup.contact_details?.phone?.[0]?.phone || null
            ) : (
                contact_lookup.account_details?.phone?.[0]?.phone || null
            )
            if (number) {
                setValue('dial', number);
                setIsDialDisabled(false);
                trigger('dial');
            } else {
                setValue('dial', "");
                setIsDialDisabled(true);
            }
        } else {
            setValue('dial', "");
            setIsDialDisabled(false);
        }
    }, [contact_lookup, setValue, trigger])

    useEffect(() => {
        if (!isActive) {
            handleClose();
        }
    }, [isActive, handleClose])

    useEffect(() => {
        dispatch(pbxConfigActions.viewDestinations());
    }, [dispatch])

    useEffect(() => {
        const pinnedDestinations = viewDestinations.filter(({ pinned }) => !!pinned);
        const isPinnedDestinationsFull = pinnedDestinations.length >= PINNED_COMPANY_DESTINATIONS_LIMIT;
        setIsPinnedDestinationsMaxed(isPinnedDestinationsFull);
        if (isCompanyExtensionExpanded) {
            setDisplayedCompanyDestinations(viewDestinations)
        } else {
            setDisplayedCompanyDestinations(pinnedDestinations);
        }
    }, [viewDestinations, isCompanyExtensionExpanded])

    return (
        <div style={{padding: 30}}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <Header style={{flex: 1}} as={"h1"}>Office View</Header>
                <Icon className='fa-solid fa-xmark closeIcon' size="big" onClick={handleClose} />
            </div>
            <div style={{ marginBottom: 25 }}>
                <OfficeViewContainer
                    header={ <OfficeViewHeader /> }
                    leftPanel={ <OfficeViewLeftPanel pages={pages} selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> }
                    rightPanel={ <OfficeViewDialPad control={control} dialedNumber={dialedNumber} dialButtonDisabled={!isValid} onDial={() => {}} /> }
                >
                    <OfficeViewContent 
                        pages={pages} 
                        selectedTab={selectedTab} 
                        onNumberClick={handleNumberClick}
                        search={searchMade}
                        isSearchResultsVisible={isSearchResultsVisible}
                        page={page}
                        isSearchingContacts={isSearchingContacts}
                        contacts_searched_count={contacts_searched_count}
                        contact_lookup={contact_lookup}
                        cancelFunction={cancelFunction}
                        searchFunction={searchFunction}
                        onChangeSearch={onChangeSearch}
                        onPersonSelect={onPersonSelect}
                        onPageChange={onPageChange}
                        onRemovePerson={onRemovePerson}
                        isDialDisabled={isDialDisabled}
                    />
                </OfficeViewContainer>
            </div>
            <div>
                <OfficeViewCompanyDestinations
                    isExpanded={isCompanyExtensionExpanded}
                    toggleIsExpanded={toggleIsExpanded}
                    companyDestinations={displayedCompanyDestinations}
                    togglePinned={togglePinned}
                    isReadingViewDestinations={isReadingViewDestinations}
                    isPinnedDestinationsMaxed={isPinnedDestinationsMaxed}
                />
            </div>
        </div>
    )
}

export default OfficeView;
